<template>
  <div>
    <v-app-bar app color="accent" class="px-4">
      <router-link :to="{ name: 'organisationHome' }">
        <v-img
          alt="muse - logo"
          src="../assets/logo.png"
          transition="scale-transition"
          width="120"
        />
      </router-link>

      <v-spacer></v-spacer>
      <v-icon
        v-if="showOrganisationButton"
        class="ma-6"
        color="white"
        @click="navigateToOrganisation()"
        >location_city</v-icon
      >
      <v-btn
        v-if="$auth.isAuthenticated"
        color="primary"
        @click="$auth.logout(logoutOptions)"
      >
        <v-icon left>power_settings_new</v-icon>
        Logout
      </v-btn>
    </v-app-bar>
    <v-main>
      <slot></slot>
    </v-main>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DefaultLayout',
  computed: {
    ...mapGetters('organisation', ['organisations']),
    ...mapGetters('currentUser', [
      'currentUserIsSuper',
      'currentUserIsAnOrgAdmin',
      'currentUserHasManyOrganisations'
    ]),
    logoutOptions() {
      return {
        returnTo: window.location.origin
      };
    },
    showOrganisationButton() {
      // Temporarily hide from all users other than super users, as the UI is
      // not quite prime-time ready.
      return this.currentUserIsSuper; // || this.currentUserIsAnOrgAdmin;
    }
  },
  mounted() {
    this.fetchCurrentUser();
  },
  methods: {
    ...mapActions('currentUser', ['fetchCurrentUser']),
    navigateToOrganisation() {
      const gotoListing =
        this.currentUserIsSuper ||
        this.currentUserHasManyOrganisations;
      if (gotoListing) {
        return this.$router.push('/organisations');
      }

      if (this.organisations) {
        const id = this.organisations[0].id;
        this.$router.push(`/organisation/${id}`);
      }
    }
  }
};
</script>
